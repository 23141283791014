/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "네 번재 에피소드에서는 Patreon, 전기차, Basecamp 장애, 개인 서버 운영에 대해서 이야기했습니다."), "\n", React.createElement(_components.p, null, "Patreon, 전기차, Basecamp, 개인 서버 운영"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/jaehyun"
  }, "후원 : 김재현님(당근마켓 대표)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.44bits.io/ko"
  }, "후원 : 44bits.io")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.hyundai.com/kr/ko/vehicles/kona-electric/"
  }, "현대자동차 코나 전기차(KONA Electric)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://youtu.be/scoIp5NKvXE"
  }, "전기차는 시기상조! - 시피 유튜브")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://m.signalvnoise.com/postmortem-on-the-read-only-outage-of-basecamp-on-november-9th-2018-9165c315ee7f"
  }, "Basecamp 서비스 장애")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/blogs/aws/new-lower-cost-amd-powered-ec2-instances/"
  }, "AWS - AMD CPU를 사용한 인스턴스 타입 출시")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.cloudflare.com/cloud-computing-without-containers/"
  }, "Cloud Computing without Containers - Cloudflare Isolates")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
